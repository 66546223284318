<template>
  <v-main
    :class="marging"
    class="fill-height"
  >
    <div
      id="core-view"
      class="pl-6 pr-6 fill-height"
    >
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </div>
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
    props: {
      marging: String,
    },
    metaInfo () {
      return {
        title: process.env.VUE_APP_TITLE
        /* title: 'Fast Data' */
      }
    },
  }
</script>
